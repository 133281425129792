<template>
  <div>
    <b-overlay
      rounded="sm"
      :show="isLoading"
      no-wrap
    />

    <b-card no-body>
      <b-card-body>
        <div class="d-flex align-items-center">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mr-1"
            :to="{ name: 'invites' }"
          >
            <feather-icon
              icon="MailIcon"
              class="mr-50"
            />
            <span class="align-middle">Инвайты</span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            :to="{ name: 'userGroups' }"
          >
            <feather-icon
              icon="UsersIcon"
              class="mr-50"
            />
            <span class="align-middle">Группы пользователей</span>
          </b-button>
        </div>
      </b-card-body>
    </b-card>

    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Фильтры
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Роль</label>
            <v-select
              :value="roleFilter"
              :options="roleOptions"
              class="w-100"
              placeholder="Выберите роль"
              @input="setRoleFilter"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Статус</label>
            <v-select
              :value="statusFilter"
              :options="statusOptions"
              class="w-100"
              placeholder="Выберите статус"
              @input="setStatusFilter"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Показывать</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>пользователей</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Имя, почта, телефон"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="usersFormatted"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Пользователи не найдены"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- ID -->
        <template #cell(id)="data">
          <b-link
            :to="{ name: 'adminInfo', params: { id: data.item.id } }"
            class="font-weight-bold d-block"
          >
            {{ data.value }}
          </b-link>
        </template>

        <!-- Пользователь -->
        <template #cell(full_name)="data">
          <b-media vertical-align="center" class="d-flex align-items-center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar_url"
                :text="replaceAvatarWithName(data.value)"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'adminInfo', params: { id: data.item.id } }"
              class="font-weight-bold d-block"
            >
              {{ data.value }}
            </b-link>
          </b-media>
        </template>

        <!-- Роль -->
        <template #cell(group_name)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Email -->
        <template #cell(email)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Номер телефона -->
        <template #cell(phone)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Страна -->
        <template #cell(country.name)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Статус -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.value)}`"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Действия -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <div v-if="data.item.status === 'Активный'">
              <feather-icon
                :id="`user-row-${data.item.id}-lock-icon`"
                icon="LockIcon"
                size="16"
                class="mx-1 cursor-pointer"
                @click="showBanUserModal(data.item)"
              />
              <b-tooltip
                title="Заблокировать пользователя"
                :target="`user-row-${data.item.id}-lock-icon`"
              />
            </div>

            <div v-else>
              <feather-icon
                :id="`user-row-${data.item.id}-unlock-icon`"
                icon="UnlockIcon"
                size="16"
                class="mx-1 cursor-pointer"
                @click="showBanUserModal(data.item)"
              />
              <b-tooltip
                title="Разблокировать пользователя"
                :target="`user-row-${data.item.id}-unlock-icon`"
              />
            </div>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Показано от {{ dataMeta.from }} до {{ dataMeta.to }} из {{ dataMeta.of }} пользователей</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BLink, BTooltip,
  BBadge, BPagination, BOverlay, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { mapGetters } from 'vuex'
import { paginateArray, sortCompare } from '@/helpers/utils'
import { showMessage } from '@/helpers/notifications'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BOverlay,
    BTooltip,
    BButton,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    tableColumns: [
      { key: 'id', label: 'ID', sortable: true },
      { key: 'full_name', label: 'Имя/Фамилия', sortable: true },
      { key: 'group_name', label: 'Роль', sortable: true },
      { key: 'email', label: 'Email', sortable: true },
      { key: 'phone', label: 'Телефон', sortable: true },
      { key: 'country.name', label: 'Страна', sortable: true },
      { key: 'status', label: 'Статус', sortable: true },
      { key: 'actions', label: 'Действия' },
    ],
    perPage: 10,
    totalUsers: 0,
    currentPage: 1,
    perPageOptions: [10, 25, 50, 100],
    searchQuery: '',
    sortBy: 'id',
    isSortDirDesc: true,

    roleFilter: null,
    statusFilter: null,
    statusOptions: [
      { label: 'Активный', value: 'Активный' },
      { label: 'Заблокирован', value: 'Заблокирован' },
    ],

    isLoading: false,
  }),
  computed: {
    ...mapGetters({
      users: 'getUsers',
      userGroups: 'getUserGroups',
    }),
    dataMeta() {
      const localItemsCount = this.$refs.refUserListTable ? this.$refs.refUserListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalUsers,
      }
    },
    roleOptions() {
      const options = []

      this.userGroups.forEach(group => options.push({
        label: group.title,
        value: group.id,
      }))

      return options
    },
    usersFormatted() {
      const users = this.users.slice()
      const queryLowered = this.searchQuery.toLowerCase()
      const roleFilter = this.roleFilter ? this.roleFilter.value : null
      const statusFilter = this.statusFilter ? this.statusFilter.value : null

      const filteredData = users.filter(
        user =>
          (user.full_name.toLowerCase().includes(queryLowered) ||
            user.email.toLowerCase().includes(queryLowered) ||
            user.phone.toLowerCase().includes(queryLowered)) &&
            user.group.id === (roleFilter !== null ? roleFilter : user.group.id) &&
            user.status === (statusFilter !== null ? statusFilter : user.status),
      )

      const sortedData = filteredData.sort(sortCompare(this.sortBy))

      if (this.isSortDirDesc) sortedData.reverse()

      const paginatedData = paginateArray(
        sortedData,
        this.perPage,
        this.currentPage,
      )

      this.totalUsers = sortedData.length

      return paginatedData
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('fetchUserGroups')
    await this.$store.dispatch('fetchUsers')
  },
  mounted() {
    if (localStorage.roleFilter) this.roleFilter = JSON.parse(localStorage.roleFilter)
    if (localStorage.statusFilter) this.statusFilter = JSON.parse(localStorage.statusFilter)
  },
  methods: {
    replaceAvatarWithName(text) {
      return avatarText(text)
    },
    resolveUserStatusVariant(status) {
      return status === 'Активный' ? 'success' : 'danger'
    },
    setRoleFilter(filter) {
      if (filter === null) {
        this.roleFilter = null
        localStorage.roleFilter = null
        return
      }

      this.roleFilter = filter
      localStorage.roleFilter = JSON.stringify({
        label: filter.label,
        value: filter.value,
      })
    },
    setStatusFilter(filter) {
      if (filter === null) {
        this.statusFilter = null
        localStorage.statusFilter = null
        return
      }

      this.statusFilter = filter
      localStorage.statusFilter = JSON.stringify({
        label: filter.label,
        value: filter.value,
      })
    },
    async showBanUserModal(user) {
      const action = user.status === 'Активный' ? 'заблокировать' : 'разблокировать'
      const variant = user.status === 'Активный' ? 'danger' : 'success'

      const message = `Вы действительно хотите ${action} пользователя - ${user.first_name} ${user.last_name}?`

      const modalStatus = await this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Требуется подтверждение',
          modalClass: `modal-${variant}`,
          okVariant: variant,
          okTitle: 'Подтвердить',
          cancelTitle: 'Отмена',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })

      if (modalStatus) await this.banUser(user.id)
    },
    async banUser(userId, status) {
      this.isLoading = true

      const res = await this.$store.dispatch('banUser', userId)

      if (res) {
        const action = status === 'Активный' ? 'заблокирован' : 'разблокирован'
        showMessage(`Пользователь успешно ${action}`, 'Успех')
      }

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.stream-title {
  font-size: 16px;
}

.stream-date {
  margin-left: 5px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/variables-dark';

.dark-layout {
  .b-overlay {
    .bg-light {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>
